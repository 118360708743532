import { TThemeType } from "./theme"

// const appLogo = require('./images/logoCB.svg')
// const loadingLogo = require('./images/logoCB.svg')
const appLogoTop = require('./images/logotop.svg')

interface IGlobalConfig {
    appLogo: any,
    appLogoTop: any,
    loadingLogo: any,
    theme: TThemeType,
}

const globalConfig: IGlobalConfig = {
    appLogo:'',
    appLogoTop,
    loadingLogo:'',
    theme: 'star',
}

export default globalConfig